const colors = {
  "yellow": "#f7ec1f",
  "yellow-orage": "#ffd108",
  "orange": "#faa71a",
  "red-orange": "#f47c20",
  "red": "#ee2c24",
  "red-violet": "#ed2490",
  "violet": "#7f4098",
  "blue-violet": "#5171FF",
  "blue": "#7D8AFF",
  "blue-green": "#00b2d7",
  "green": "#21b24b",
  "yeloow-green": "#85c441",
};

export const getColor = (key) => {
  return key in colors ? colors[key] : null
};

export const getRandomColor = () => {
  const keys = Object.keys(colors);
  const i = getRandomInt(keys.length);
  return getColor(keys[i]);
}

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}