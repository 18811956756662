import { createMessage, getAllMessages, updateMessage } from "@/firebase/messages";
import { Failure, Success } from "@/lib/success";
import { Message } from "@/models";

export default {
  namespaced: true,
  state: {
    all: {}
  },
  getters: {
    parsedMessages: (state) => {
      return Object.keys(state.all).map(id => {
        const message = state.all[id];
        message.id = id;
        return message;
      }).sort((a, b) => {
        return a.date - b.date
      });
    }
  },
  mutations: {
    addMessage: (store, {id, message}) => {
      store.all[id] = message;
    },
    setAllMessages: (store, messages) => {
      store.all = {...store.all, ...messages};
    },
    updateMessage: (store, {id, message}) => {
      store.all[id] = message
    }
  },
  actions: {
    addMessage: async ({commit, rootGetters}, message) => {
      const accountId = rootGetters['accounts/primaryAccountId'];
      message = new Message({...message, accountId})
      const {data: id} = await createMessage(message)
      commit('addMessage', {id, message})
    },
    getAllMessages: async ({commit, rootGetters}) => {
      const accountId = rootGetters['accounts/primaryAccountId']
      if (accountId) {
        const {success, data} = await getAllMessages(accountId);

        if (success) {
          commit('setAllMessages', data);
          return new Success()
        }
        return new Failure('no messages')
      }
      return new Failure('no root account')
    },
    updateMessage: async ({commit}, {id, message}) => {
      try {
        const {success} = updateMessage(id, message);
        
        if (success) {
          commit('updateMessage', {id, message})
          return new Success()
        }
      } catch (err) {
        console.error(err)
      }
    }
  }
}