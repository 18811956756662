import { sendSignInLinkToEmail, signInWithEmailLink, isSignInWithEmailLink, onAuthStateChanged } from "firebase/auth";
import {auth} from './firebase';
import error from '@/lib/error'

const actionCodeSettings = {
  "url": location.origin,
  "handleCodeInApp": true
};

const localStoreageId = 'threades_emailForSignIn';

export const check = () => new Promise((res, rej) => {
  const unsubscribe = onAuthStateChanged(auth, (user) => {
    unsubscribe();
    if (user) {
      return res(user);
    } else {
      return res(null)
    }
  }, rej);
});

export const getCurrentUser = () => auth.currentUser;

export const getIdToken = async () => auth.currentUser.getIdToken(true);

export const signOut = () => auth.signOut();

export const signin = async ({email, path = '/confirm'}) => {
  try {
    const settings = {...actionCodeSettings}
    settings.url += path;
    await sendSignInLinkToEmail(auth, email, settings);
    window.localStorage.setItem(localStoreageId, email);
  } catch (err) {
    return error(null, err)
  }
}

export const confirm = async ({href, email = null}) => {
  try {
    if (isSignInWithEmailLink(auth, href)) {
      email = email ? email : window.localStorage.getItem(localStoreageId);
  
      if (!email) {
        throw 'no_email'
      }

      const result = await signInWithEmailLink(auth, email, href);

      if (result) {
        window.localStorage.removeItem(localStoreageId);
        return result;
      } else {
        throw 'login_failed'
      }

    } else {
      throw 'invalid_auth_link'
    }
  } catch (err) {
    return error(err)
  }
}

