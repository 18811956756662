import { firestore } from './firebase';
import { doc, collection, updateDoc, getDocs, addDoc, query, where } from 'firebase/firestore';
import { parseDocs } from './lib/parse_docs';
import { Failure, Success } from '@/lib/success';

const accounts = collection(firestore, "accounts");

export const createAccount = async (data) => {
  try {
    const docRef = await addDoc(accounts, data);
    return new Success(docRef.id)
  } catch (err) {
    return console.error(err)
  }
};

export const getAllAccounts = async (uid) => {
  try {
    const queryUser = query(accounts, where('uid', '==', uid));
    const queryShared = query(accounts, where('collaborators', 'array-contains', uid));
    
    const [userAccounts, sharedAccounts] = await Promise.all([await getDocs(queryUser), await getDocs(queryShared)]);

    if (userAccounts.empty && sharedAccounts.empty ) {
      return new Failure('no accounts')
    }
    
    return new Success({...parseDocs(userAccounts.docs), ...parseDocs(sharedAccounts.docs)});
  } catch (err) {
    return console.error(err)
  }
};

export const updateAccount = async (id, data) => await updateDoc(doc(accounts, id), data)