import {firestore} from './firebase';
import { doc, collection, updateDoc, getDocs, addDoc, query, where } from 'firebase/firestore';
import {parseDocs} from './lib/parse_docs';
import { Success, Failure } from '@/lib/success';

const hashtags = collection(firestore, "hashtags");

export const createHashtag = async (data) => {
  try {
    const docRef = await addDoc(hashtags, data);
    return new Success(docRef.id);
  } catch (err) {
    console.error(err)
  }
};

export const createHashtags = async (newHashtags) => {
  try {
    const accumulator = {};
    for (const hashtag of newHashtags) {
      const docRef = await addDoc(hashtags, hashtag);
      accumulator[docRef.id] = hashtag
    }
    return new Success(accumulator);
  } catch (err) {
    console.error(err)
  }
};

export const getAllHashtags = async (id) => {
  try {
    const queryAccount = query(hashtags, where('accountId', '==', id));
    const queryShared = query(hashtags, where('mentions', 'array-contains', id));

    const accountHashtags =  await getDocs(queryAccount);
    const sharedHashtags = await getDocs(queryShared);

    if (accountHashtags.empty && sharedHashtags.empty ) {
      return new Failure('no hashtags')
    }

    return new Success({...parseDocs(accountHashtags.docs), ...parseDocs(sharedHashtags.docs)});
  } catch (err) {
    console.error(err)
  }
};

export const updateHashtag = async (id, data) => {
  await updateDoc(doc(hashtags, id), data);
  return new Success({id, data})
}