import {signin, confirm, check, signOut} from '@/firebase/auth';
import { Success, Failure } from '@/lib/success';

export default {
  namespaced: true,
  state: {
    uid: null,
  },
  getters: {},
  mutations: {
    setUid (state, uid) {
      state.uid = uid
    }
  },
  actions: {
    signIn: async (_, email) => await signin(email),
    confirm: async ({dispatch}, {href, email}) => {
      try {
        const {user} = await confirm({href, email});
        let accounts = await dispatch('accounts/getAllAcounts', {uid: user.uid}, {root: true});

        if (accounts.success) {
          return new Success(user);
        }

        accounts = await dispatch('accounts/createAccount', {uid: user.uid, primary: true}, {root: true});
        if (accounts.success) {
          return new Success(user);
        }
        
        return new Failure() 
      } catch (err) {
        console.error(err)
      }
    },
    check: async ({dispatch, commit}) => {
      const user = await check();

      if (user) {
        commit('setUid', user.uid);
        const accounts = await dispatch('accounts/getAllAcounts', {uid: user.uid}, {root: true});
        
        if (accounts.success) {
          return new Success(user);
        }
        return new Success(user);
      }

      return new Failure('not logged in')
    },
    signOut: () => signOut(),
  }
}