export class Success {
  constructor (data) {
    return {
      success: true,
      data
    }
  }
}

export class Failure {
  constructor (error) {
    return {
      success: false,
      error
    }
  }
}