import { createAccount, getAllAccounts } from '@/firebase/accounts';
import {Account} from '@/models';
import { Failure, Success } from '@/lib/success';

export default {
  namespaced: true,
  state: {
    all: {}
  },
  getters: {
    primaryAccountId (state) {
      return Object.keys(state.all).find(id => {
        const account = state.all[id];
        return account.primary
      })
    }
  },
  mutations: {
    setAccount(state, {id, account}) {
      state.all[id] = account
    },
    setAllAccounts(state, accounts) {
      state.all = {...state.all, ...accounts}
    }
  },
  actions: {
    createAccount: async ({commit}, {uid, primary = false}) => {
      try {
        const account = new Account({uid, primary});
        const {success, data: {id}} = await createAccount(account);

        if (success) {
          commit('setAccount', {id, account});
          return new Success({id, account});
        }
      } catch (err) {
        console.error(err)
      }
    },
    getAllAcounts: async ({commit}, {uid}) => {
      try {
        const {success, data} = await getAllAccounts(uid);
        
        if (success) {
          commit('setAllAccounts', data);
          return new Success(data);
        } else {
          return new Failure('no accounts')
        }
      } catch (err) {
        console.error(err)
      }
    }
  }
}