import {required} from '@/lib/util/required'

export default class {
  constructor ({accountId = required(), type = '', hashtags = required(), content = required(), primaryHashtag = required(), mentions = []}) {
    return {
      accountId,
      type,
      content,
      date: Date.now(),
      hashtags,
      primaryHashtag,
      mentions
    }
  }
}
