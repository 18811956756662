import {firestore} from './firebase';
import { doc, collection, updateDoc, getDocs, addDoc, query, where, orderBy, limit } from 'firebase/firestore';
import {parseDocs} from './lib/parse_docs';
import { Success, Failure } from '@/lib/success';

const messages = collection(firestore, "messages");

export const createMessage = async (data) => {
  try {
    const docRef = await addDoc(messages, data);
    return new Success(docRef.id);
  } catch (err) {
    console.error(err)
  }
};

export const getAllMessages = async (id) => {
  try {
    const queryUser = query(messages, orderBy("date"), where('accountId', '==', id), limit(500));
    const queryShare = query(messages, orderBy("date"), where('mentions', 'array-contains', id), limit(500));

    const yourMessages =  await getDocs(queryUser);
    const mentionedMessages = await getDocs(queryShare);

    if (yourMessages.empty && mentionedMessages.empty ) {
      return new Failure('no accounts')
    }

    return new Success({...parseDocs(yourMessages.docs), ...parseDocs(mentionedMessages.docs)});
  } catch (err) {
    console.error(err)
  }
};

export const updateMessage = async (id, message) => {
  await updateDoc(doc(messages, id), message);
  return new Success(message)
}