import {required} from '@/lib/util/required'

export default class {
  constructor ({accountId = required(), name = required(), color = required(), mentions = []}) {
    return {
      accountId,
      mentions,
      name,
      color
    }
  }
}