import { initializeApp } from "firebase/app"
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore"
import {getAuth, connectAuthEmulator} from 'firebase/auth'

import config from './config.json';
import emulatorConfig from '../../firebase.json'

export const app = initializeApp(config);
export const firestore = getFirestore();
export const auth = getAuth();

if (process.env.NODE_ENV === "development") {
  connectFirestoreEmulator(firestore, "localhost", emulatorConfig.emulators.firestore.port);
  connectAuthEmulator(auth, `http://localhost:${emulatorConfig.emulators.auth.port}/`)
}