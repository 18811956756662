import { createHashtag, getAllHashtags, createHashtags } from '@/firebase/hashtags';
import { Hashtag } from '@/models';
import { Failure, Success } from '@/lib/success';
import { getRandomColor } from '@/lib/color';

export default {
  namespaced: true,
  state: {
    all: {}
  },
  getters: {
    parsedHashtags (state) {
      return Object.keys(state.all).map((id) => {
        const hashtag = state.all[id];
        hashtag.id = id;
        return hashtag
      });
    }
  },
  mutations: {
    setHashtag(state, {id, account}) {
      state.all[id] = account
    },
    setAllHashtags(state, hashtags) {
      state.all = {...state.all, ...hashtags}
    },
  },
  actions: {
    createHashtag: async ({commit, rootGetters}, {accountId, name, color}) => {
      try {
        accountId = accountId || rootGetters['accounts/primaryAccountId'];
        const hashtag = new Hashtag({accountId, name, color: color || getRandomColor() });
        const {success, data: {id}} = await createHashtag(hashtag);

        if (success) {
          commit('setHashtag', {id, hashtag});
          return new Success({id, hashtag});
        }
      } catch (err) {
        console.error(err)
      }
    },
    createHashtags: async ({commit, rootGetters}, {accountId, hashtags}) => {
      try {
        accountId = accountId || rootGetters['accounts/primaryAccountId'];
        hashtags = hashtags.map((name) => new Hashtag({accountId, name, color: getRandomColor()}))
        
        const {success, data} = await createHashtags(hashtags);

        if (success) {
          commit('setAllHashtags', data);
          return new Success(data);
        }
      } catch (err) {
        console.error(err)
      }
    },
    getAllHashtags: async ({commit, rootGetters}) => {
      const accountId = rootGetters['accounts/primaryAccountId']
      if (accountId) {
        const {success, data} = await getAllHashtags(accountId);

        if (success) {
          commit('setAllHashtags', data);
          return new Success()
        }
        return new Failure('no messages')
      }
      return new Failure('no root account')
    }
  }
}