import {required} from '@/lib/util/required'

export default class {
  constructor ({uid = required(), name = '', primary = false, lastHashtag = null, hashtags = []}) {
    return {
      uid,
      name,
      primary,
      lastHashtag,
      hashtags
    }
  }
}