import { createStore } from 'vuex'
import auth from './auth'
import accounts from './accounts'
import hashtags from './hashtags'
import messages from './messages'

const store = createStore({
  modules: {accounts, auth, hashtags, messages},
  state: {
    browserWidth: null,
    browserHeight: null
  },
  mutations: {
    setBrowserWidth (state, width) {
      state.browserWidth = width
    },
    setBrowserHeight (state, height) {
      state.browserHeight = height
    }
  },
  actions: {
    setVhVar ({commit}, height) {
      document.documentElement.style.setProperty("--vh", `${height * 0.01}px`);
      commit('setBrowserHeight', height)
    }
  }
});

export default store;